<template>
  <div class="check-in-page view-page">
    <c-search searchTitle="员工姓名电话" placeholder="姓名" valueFormat="yyyyMMdd" :isDate="true" :scope="false" @search="search"></c-search>
    <c-navigation title="部门" :list="$store.state.departmentListAll" :isBorder="true" @change="changeDepartment"> </c-navigation>
    <div class="checkIn-case flex align-center justify-between">
      <!-- <div class="checkIn">
        <span>打卡wifi 杰律事务所</span>
        <p @click="checkbox">修改打卡位置</p>
      </div> -->
      <div class="export">导出</div>
    </div>
    <c-table :data="tableData">
      <!-- <el-table-column align="center" prop="date" label="选择">
        <template #default="{ row }">
          <el-checkbox></el-checkbox>
          {{ row.data }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="username" label="员工姓名"> </el-table-column>
      <el-table-column align="center" prop="mobile" label="手机号"> </el-table-column>
      <el-table-column align="center" prop="section" label="部门"> </el-table-column>
      <el-table-column align="center" prop="startime" label="早上卡"> </el-table-column>
      <el-table-column align="center" prop="endtime" label="晚上卡"> </el-table-column>
      <el-table-column align="center" prop="day" label="时间"> </el-table-column>
    </c-table>

    <!-- 新增文件交接 -->
    <c-dialog v-model="isAdd" title="设置打开地点" @confirm="addAddress">
      <div class="map">
        <input type="text" placeholder="搜索" />
        <div id="container"></div>
      </div>
    </c-dialog>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="everyDayDataParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import * as hrApi from '@/api/hr.js'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  components: {},
  name: 'CheckIn',
  data() {
    return {
      // 列表信息
      tableData: [],
      map: null,
      isAdd: false, // 控制弹框
      everyDayDataParam: {
        username: '',
        // 20221017
        day: 20221017,
        section_id: '',
        page: 1,
        limit: 7
      },
      contactsTotal: 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    /**
     * @description: 初始化函数
     */
    async init() {
      const d = new Date()
      this.everyDayDataParam.day = d.getFullYear() + (d.getMonth() + 1).toString().padStart(2, 0) + d.getDate().toString().padStart(2, 0)
      this.getEveryDayData()
    },
    initMap() {
      AMapLoader.load({
        key: 'a8b40f234f0d0f009d59829401f9f7cd', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(AMap => {
          this.map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 5, //初始化地图级别
            center: [105.602725, 37.076636] //初始化地图中心点位置
          })
        })
        .catch(e => {
        })
    },
    checkbox() {
      this.isAdd = true
      this.initMap()
    },
    /**
     * 得到地址
     */
    addAddress() { },
    /**
     * @description: 搜索
     * @param {*} v: 数据参数
     */
    search(v) {
      if (v.keyWord || v.date) {
        this.everyDayDataParam.username = v.keyWord
        this.everyDayDataParam.day = v.date || ''
        this.getEveryDayData()
      } else {
        const d = new Date()
        this.everyDayDataParam.username = ''
        this.everyDayDataParam.day = d.getFullYear() + (d.getMonth() + 1).toString().padStart(2, 0) + d.getDate().toString().padStart(2, 0)
        this.getEveryDayData()
      }
    },
    /**
     * @description: 获取每日打卡的数据
     */
    getEveryDayData() {
      hrApi.getEveryDayData(this.everyDayDataParam).then(res => {
        this.tableData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * @description: 切换部门
     * @param {*} v: 切换后得部门参数
     * @return {*}
     */
    changeDepartment(v) {
      this.everyDayDataParam.section_id = v.id
      this.getEveryDayData()
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.everyDayDataParam.page = i
      this.getEveryDayData()
    }
  }
}
</script>

<style lang="scss" scoped>
.check-in-page {
  background-color: #fff;
}
.checkIn-case {
  padding: 1.6rem 1.6rem 1.3rem;
}
.checkIn {
  display: flex;
  align-items: center;
  width: 17.21rem;
  height: 2rem;
  background: #f5f5f5;
  border: 1px solid #e9f4f3;
  border-radius: 4px;
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 0.79rem;
    font-weight: 400;
    color: #333333;
  }
  > p {
    cursor: pointer;
    font-size: 0.83rem;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.85rem;
    height: 1.95rem;
    background: linear-gradient(109deg, #2fca70, #05c154);
    border-radius: 0.31rem;
  }
}
.export {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.78rem;
  height: 1.95rem;
  background: linear-gradient(109deg, #fc9940, #f37902);
  border-radius: 0.31rem;

  font-size: 0.83rem;
  font-weight: 400;
  color: #ffffff;
}
.list-item {
  padding: 0 1.6rem;
}
#container {
  // padding: 0px;
  // margin: 0px;
  width: 28.8rem;
  height: 26.93rem;
}

.map {
  input {
    width: 19.27rem;
    height: 2.34rem;
    background: #ebf0f0;
    border-radius: 1.17rem;
    border: none;
  }
}
</style>
